<template>
    <div class="popDialog">
        <el-dialog
                v-if="editDialog"
                width="95%"
                top="30px"
                :visible.sync="editDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog"
        >
            <template v-slot:title>
                <el-row>
                    <el-col :span="24" class="selectTitleMax">
                        <span>編輯資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="24" class="selectTitleMid">
                                <span>帳號資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="companyId" placeholder="" @change="handleCompanyChange"
                                           :disabled="isCompanyid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>分店<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="branchId" placeholder="" :disabled="isBranchid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in branchOptions"
                                            :key="item.branchId"
                                            :label="item.branchName"
                                            :value="item.branchId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <!--							<el-col :span="selectTitleSpan" class="selectTitle">-->
                            <!--								<span>帳號<span class="red">*</span>:</span>-->
                            <!--							</el-col>-->
                            <!--							<el-col :span="selectInpudSpan" class="selectInpud">-->
                            <!--								<el-input v-model="userName" placeholder="請輸入帳號" @input="validateUsername"></el-input>-->
                            <!--							</el-col>-->
                        </el-row>
                        <el-row>
                            <!--							<el-col :span="selectTitleSpan" class="selectTitle">-->
                            <!--								<span>密碼<span class="red">*</span>:</span>-->
                            <!--								&lt;!&ndash;                                <tr><span class="red" style="font-size: 10px">(至少8碼，需包含大小寫，數字，特殊符號)</span></tr>&ndash;&gt;-->
                            <!--							</el-col>-->
                            <!--							<el-col :span="selectInpudSpan" class="selectInpud">-->
                            <!--								<el-input-->
                            <!--									:type="inputType"-->
                            <!--									v-model="password"-->
                            <!--									placeholder="需包含大小寫,數字,特殊符號"-->
                            <!--									style="width: calc(100% - 30px)"-->
                            <!--								></el-input>-->
                            <!--								<span class="eye-icon" @click="togglePasswordVisibility"> {{ passwordVisible ? '👁️' : ' 👁️‍🗨️' }}</span>-->
                            <!--							</el-col>-->
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="isActive">
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>錯誤次數:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        v-model="errorCount"
                                        placeholder="請輸入錯誤次數"
                                        @input="validateErrorCount"
                                        type="number"
                                ></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>信箱:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="mail" placeholder="請輸入信箱"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>角色<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="role" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in roleOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>帳號狀態<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="state">
                                    <el-radio label="0">鎖定</el-radio>
                                    <el-radio label="1">使用中</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>解除時間:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-date-picker
                                        v-model="unlockDate"
                                        type="datetime"
                                        placeholder="選擇日期和時間"
                                        @change="dateChangeFormat"
                                >
                                </el-date-picker>
                            </el-col>
                        </el-row>
<!--                        <el-row>-->
<!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
<!--                                <span>信箱:</span>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
<!--                                <el-input v-model="mail" placeholder="請輸入信箱"></el-input>-->
<!--                            </el-col>-->
<!--                        </el-row>-->
                        <el-row>
                            <el-col :span="24" class="selectTitleMid">
                                <span>會員資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>姓:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="name" placeholder="請輸入姓"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>名:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="lastName" placeholder="請輸入名"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>稱謂:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="title" placeholder="請選擇稱謂">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option label="先生" value="先生"></el-option>
                                    <el-option label="小姐" value="小姐"></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>暱稱<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="nickName" placeholder="請輸入暱稱" @input="validateName"></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>性別:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-radio-group v-model="gender">
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="0">女</el-radio>
                                </el-radio-group>
                            </el-col>
<!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
<!--                                <span>年齡:</span>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
<!--                                <el-input v-model="age" placeholder="請輸入年齡" type="number"-->
<!--                                          @input="validateAge"></el-input>-->
<!--                            </el-col>-->
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>生日:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-date-picker v-model="birthday" type="date" placeholder="選擇日期"
                                                @change="handleDateChange">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>聯絡電話<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        v-model="phoneNumber"
                                        placeholder="請輸入手機號碼"
                                        v-on:change="validatePhoneNumber"
                                ></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>住址:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="address" placeholder="請輸入住址"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="infoRemark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="editCar">編輯會員資產</el-button>
                <el-button @click="editSave" type="primary">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    // 导入Moment.js
    import moment from 'moment'

    export default {
        name: 'editDialog',
        components: {},
        props: {
            editDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                row: null,
                token: null,
                companyId: null,
                //表頭設定
                columns: [],
                //table數據
                // tableData: [],
                customerInfoId: null,
                name: null,
                lastName: null,
                title: null,
                nickName: null,
                gender: null,
                age: null,
                birthday: null,
                phoneNumber: null,
                address: null,
                infoRemark: null,
                companyOptions: [],
                branchOptions: [],
                customerId: null,
                selectCompanyId: null,
                branchId: null,
                selectBranchId: null,
                userName: null,
                password: null,
                passwordVisible: false,
                errorCount: 0,
                role: null,
                isActive: null,
                state: null,
                unlockDate: null,
                unlockDateString: null,
                mail: null,
                roleOptions: [
                    {value: '99', label: '一般會員'},
                    {value: '999', label: 'VIP會員'},
                    {value: '9999', label: 'VVIP會員'},
                ],
                reload: false,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
            }
        },
        computed: {
            inputType() {
                return this.passwordVisible ? 'text' : 'password'
            },
        },
        mounted() {
            // this.columns = tableColumns;
        },
        methods: {
            init(row) {
                this.selectCompanyId = null
                this.selectBranchId = null
                this.row = row.info
                if (row != null && row != '') {
                    this.customerId = row.customerId
                    this.userName = row.userName
                    this.password = row.password
                    this.errorCount = row.errorCount
                    this.isActive = row.isActive == true ? '1' : '0'
                    this.companyId = row.companyId
                    this.branchId = row.branchId
                    this.role = row.role.toString()
                    this.state = row.state == 0 ? '0' : '1'
                    this.unlockDate = row.unlockDate
                    // this.unlockDate = row.unlockDate == null || row.unlockDate == '' ? null : this.dateFormat(row.unlockDate.toString());
                    this.mail = row.mail
                }
                if (this.row != null && this.row != '') {
                    this.customerInfoId = this.row.customerInfoId
                    this.name = this.row.name
                    this.lastName = this.row.lastName
                    this.title = this.row.title
                    this.nickName = this.row.nickName
                    this.gender = this.row.gender == null ? null : this.row.gender == true ? '1' : '0'
                    this.age = this.row.age
                    this.birthday = this.row.birthday
                    this.phoneNumber = this.row.phoneNumber
                    this.address = this.row.address
                    this.infoRemark = this.row.remark
                }
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
                const loginReturnDto = localStorage.getItem('loginReturnDto')
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto)
                    this.token = this.loginReturnDto.token
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId
                        this.selectCompanyId = row.companyId
                        this.selectBranchId = row.branchId
                        console.log('editDialog取得loginReturnDto.companyId=' + this.companyId)
                    }
                    //console.log("editCompanyDialog取得token=" + this.token);
                }
                this.searchCompanyList()
                this.searchBranchList()
            },
            editCar() {
                //跳轉到汽車列表頁
                this.$router.push({name: '會員資產資料', params: {customerId: this.row.customerId}})
                //this.$router.push({path: '/home/customer/customerCarHome', params: {customerId: this.row.customerId}});
            },
            async editSave() {
                //編輯儲存
                let tmp = this
                let url = 'consumer/updated/consumer'
                try {
                    var info = {
                        customerInfoId: this.customerInfoId,
                        name: this.name,
                        lastName: this.lastName,
                        title: this.title,
                        nickName: this.nickName,
                        gender: this.gender == null ? null : parseInt(this.gender, 10),
                        age: this.age == null ? null : parseInt(this.age, 10),
                        birthday: this.formattedBirthday == null || this.formattedBirthday == '' ? null : this.formattedBirthday,
                        phoneNumber: this.phoneNumber,
                        address: this.address,
                        remark: this.infoRemark,
                    }
                    let parameter = {
                        customerId: this.customerId,
                        userName: this.phoneNumber,
                        password: this.phoneNumber,
                        errorCount: this.errorCount == null ? null : parseInt(this.errorCount, 10),
                        isActive: this.isActive == null ? null : this.isActive == '0' ? false : true,
                        companyId: this.companyId,
                        branchId: this.branchId,
                        role: this.role == null ? null : parseInt(this.role, 10),
                        state: this.state == null ? null : parseInt(this.state, 10),
                        unlockDate: null,
                        unlockDateString:
                            this.unlockDateString == null || this.unlockDateString == '' ? this.unlockDate : this.unlockDateString,
                        mail: this.mail,
                        info: info,
                    }
                    if (!this.checkEditParameter(parameter)) {
                        console.log('更新驗證資料失敗!')
                        return
                    }
                    tmp.reload = true
                    tmp.loadingView()
                    let responseData = await tmp.$api.doPostByMsgToken(parameter, url, this.token)
                    if (responseData) {
                        // tmp.$api.openMsg("編輯資料完成!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        console.log('編輯儲存-編輯資料完成!')
                        setTimeout(function () {
                            tmp.closeDialog()
                            // tmp.$emit('init');
                        }, 1000)
                    } else {
                        // tmp.$api.openMsg("編輯資料失敗!", "error");
                        console.log('編輯儲存-編輯資料失敗!')
                    }
                } catch (error) {
                    console.log('編輯資料發生異常! error=' + error)
                } finally {
                    tmp.closeLoading()
                }
                tmp.closeLoading()
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
            },
            closeDialog() {
                console.log('關閉Pop')
                this.$emit('update:reload', this.reload)
                this.$emit('closeDialog')
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close()
                }
            },
            handleDateChange(date) {
                if (date) {
                    // 將 Date 對象轉換為字串，格式為 YYYY-MM-DD
                    this.formattedBirthday = date.toISOString().slice(0, 10)
                } else {
                    this.formattedBirthday = ''
                }
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: this.selectCompanyId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.companyOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this
                let url = 'login/search/searchBranchOptions'
                let parameter = {
                    companyId: this.selectCompanyId,
                    branchId: this.selectBranchId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.branchOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            togglePasswordVisibility() {
                //檢視密碼
                this.passwordVisible = !this.passwordVisible
            },
            checkEditParameter(parameter) {
                var ststus = true
                if (parameter != null && parameter != '') {
                    // if (parameter.userName == null || !parameter.userName) {
                    //     console.log('帳號不可為空!')
                    //     this.$api.openMsg('帳號不可為空!', 'error')
                    //     return false
                    // }
                    // if (parameter.password == null || !parameter.password) {
                    //     console.log('密碼不可為空!')
                    //     this.$api.openMsg('密碼不可為空!', 'error')
                    //     return false
                    // } else if (!this.validatePassword()) {
                    //     console.log('密碼設定錯誤!')
                    //     return false
                    // }
                    if (parameter.companyId == null || !parameter.companyId) {
                        console.log('所屬公司不可為空!')
                        this.$api.openMsg('所屬公司不可為空!', 'error')
                        return false
                    }
                    if (parameter.branchId == null || !parameter.branchId) {
                        console.log('所屬分店不可為空!')
                        this.$api.openMsg('所屬分店不可為空!', 'error')
                        return false
                    }
                    console.log('角色=' + parameter.role)
                    if (parameter.role == null) {
                        console.log('角色不可為空!')
                        this.$api.openMsg('角色不可為空!', 'error')
                        return false
                    }
                    var info = parameter.info
                    if (info.nickName == null || !info.nickName) {
                        console.log('暱稱不可為空!')
                        this.$api.openMsg('暱稱不可為空!', 'error')
                        return false
                    }
                    if (info.phoneNumber == null || !info.phoneNumber) {
                        console.log('手機號碼不可為空!')
                        this.$api.openMsg('手機號碼不可為空!', 'error')
                        return false
                    }
                } else {
                    console.log('必填資料不可為空!')
                    this.$api.openMsg('必填資料不可為空!', 'error')
                    return false
                }
                return ststus
            },
            dateChangeFormat(dateStr) {
                if (dateStr != null) {
                    var outputFormat = 'YYYY-MM-DD HH:mm:ss'
                    // 创建一个新的 Date 对象
                    var date = new Date(dateStr.toString())
                    // 检查日期是否有效
                    if (isNaN(date.getTime())) {
                        return null
                    }
                    // 使用 moment.js 库来转换日期格式
                    var formattedDate = moment(date).format(outputFormat)
                    console.log('formattedDate=' + formattedDate)
                    this.unlockDateString = formattedDate
                }
            },
            handleCompanyChange(value) {
                console.log('Selected company ID:', value)
                //系統管理員權限時會查詢一次該公司所屬的分店
                this.branchId = null
                if (!this.isCompanyid) {
                    this.selectCompanyId = value
                    this.selectBranchId = null
                    this.searchBranchList()
                }
            },
            validateUsername() {
                // 使用正則表達式檢查用戶名是否僅包含英數字大小寫以及 "-" 和 "_"
                // this.userName = this.userName.replace(/[^a-zA-Z0-9-_]/g, '');
                this.userName = this.$api.validateInput(this.userName, 'userName')
            },
            validatePassword() {
                // 密码长度至少为8个字符
                if (this.password.length < 8) {
                    this.$api.openMsg('密碼長度至少為8個字符!', 'error')
                    console.log('密碼長度至少為8個字符')
                    return false
                }
                // 包含至少一个数字
                if (!/\d/.test(this.password)) {
                    this.$api.openMsg('密碼需包含至少一個數字!', 'error')
                    console.log('密碼需包含至少一個數字')
                    return false
                }
                // 包含至少一个小写字母
                if (!/[a-z]/.test(this.password)) {
                    this.$api.openMsg('密碼需包含至少一個小寫字母!', 'error')
                    console.log('密碼需包含至少一個小寫字母')
                    return false
                }
                // 包含至少一个大写字母
                if (!/[A-Z]/.test(this.password)) {
                    this.$api.openMsg('密碼需包含至少一個大寫字母!', 'error')
                    console.log('密碼需包含至少一個大寫字母')
                    return false
                }
                // 包含至少一个特殊字符
                if (!/[^a-zA-Z0-9]/.test(this.password)) {
                    this.$api.openMsg('密碼需包含至少一個特殊字符!', 'error')
                    console.log('密碼需包含至少一個特殊字符')
                    return false
                }
                return true
            },
            validateErrorCount() {
                //this.errorCount = this.errorCount.replace(/[^\d]/g, '');
                this.errorCount = this.$api.validateInput(this.errorCount, 'errorCount')
            },
            validatePhoneNumber() {
                this.phoneNumber = this.$api.validateInput(this.phoneNumber, 'phone')
            },
            validateAge() {
                this.age = this.$api.validateInput(this.age, 'age')
            },
            validateName() {
                this.nickName = this.$api.validateInput(this.nickName, 'name')
            },
            validateEmail() {
                this.mail = this.$api.validateInput(this.mail, 'email')
            },
        },
    }
</script>

<style scoped>
    img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }
</style>
